module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"once":true,"disable":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Anja Schneck UX Designer","short_name":"anjaschneck","start_url":"/","background_color":"#4404f7","theme_color":"#4404f7","display":"minimal-ui","icon":"static/favicon.svg","icon_options":{"purpose":"maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e545101f2a841a34c83517fb0dc8e451"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
